.customDatePicker {
  color: var(--Regular, #1f1f1f) !important;
  text-align: center !important;
  font-family: Inter !important;
  font-size: 0.8rem !important;
  background-color: #fff !important;
  border: 0px !important;
  border-radius: 0.3rem !important;
  display: inline-block !important;
  position: relative !important;
  box-shadow: 0px 0.89671px 1.79343px 0px rgba(0, 14, 51, 0.25) !important;
}

.react-datepicker__day {
  border-radius: 5.38px !important;
  border: 1px solid var(--border-line-color, #d8dee5) !important;
  background: var(--basic-white, #fff) !important;
  width: 40px !important;
  height: 40px !important;
  padding: 6px !important;
  color: #000 !important;
  /* Basic */
  box-shadow: 0px 1px 1px 0px rgba(0, 14, 51, 0.05) !important;
}

.react-datepicker__day--selected {
  border-radius: 5.38px !important;
  background: var(--Blue-details, #0d85ff) !important;
  text-align: center !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
  width: 40px !important;
  height: 40px !important;
  padding: 6px !important;
  color: #fff !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__header {
  text-align: center !important;
  background-color: transparent !important;
  border-bottom: 0px !important;
  border-top-left-radius: 0.3rem !important;
  padding: 21px 0 !important;
  position: relative !important;
  font-weight: 600 !important;
}

.react-datepicker__current-month {
  color: #000 !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  padding-bottom: 21px !important;
}

.react-datepicker__month-container {
  background: transparent !important;
  padding: 38px !important;
  bottom: 0px !important;
  top: 100px !important;
}

.react-datepicker__navigation {
  /* display: flex !important;
  width: 30px !important;
  height: 30px !important;
  padding: 9.239px 8.695px 8.695px 9.239px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  border-radius: 71.737px !important;
  border: 1px solid var(--border-line-color, #d8dee5) !important;
  background: #efefef !important;
  box-shadow: 0px 0.89671px 0.89671px 0px rgba(0, 14, 51, 0.05) !important; */
  top: 21px !important;
}

.react-datepicker__navigation--next {
  right: 12px !important;
}
.react-datepicker__navigation--previous {
  left: 12px !important;
}

.react-datepicker__day-names {
  display: flex !important;
  justify-content: space-evenly !important;
}

.react-datepicker__input-container input {
  width: 100% !important;
  height: 42px !important;
  flex-shrink: 0 !important;
  border-radius: 8px !important;
  background: #f9f9f9 !important;
  border-width: 0px !important;
  padding-left: 30px !important;
}

.react-datepicker__input-container {
  display: flex !important;
  align-items: center !important;
}

.react-datepicker-ignore-onclickoutside {
  border: 1px solid var(--Blue-details, #0d85ff) !important;
  background: #f9f9f9 !important;
}

.react-datepicker-popper {
  bottom: 0px !important;
  left: 0px !important;
  top: auto !important;
  transform: translate3d(0px, 0px, 0px) !important;
  margin-bottom: 0px !important;
}

.react-datepicker {
  border-width: 0px !important;
  border-radius: 8px !important;
  border: 0.897px solid #fff !important;
  background: #fff !important;
  box-shadow: 0px 8.59px 15.59px -14px rgba(0, 14, 51, 0.22) !important;
}

.react-datepicker__day--today {
  border-radius: 5.38px !important;
  border: 1.793px solid var(--Blue-details, #0d85ff) !important;
  background: #fff !important;
  color: var(--Regular, #1f1f1f) !important;
  text-align: center !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.react-datepicker__day--outside-month {
  border-width: 0px !important;
  color: rgba(0, 23, 84, 0.15) !important;
  text-align: center !important;
  font-family: Inter !important;
  font-style: normal !important;
  box-shadow: none !important;
}

.datepicker-button {
  border-radius: 71.737px;
  background: #efefef;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-width: 0px;
}

.datepicker-button img {
  width: 7px;
}
.datepicker-span-name {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.react-datepicker__day--keyboard-selected {
  color: #000 !important;
}

.react-datepicker__portal {
  align-items: flex-end !important;
}
